<!-- Passenger Detail Modal -->
<div
  class="modal fade"
  id="customerDetailsModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel4"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content slide-in-modal">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel4">Passenger Details</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <form [formGroup]="passengerForm">
        <div class="modal-body" style="padding: 20px">
          <div class="mb-3">
            <div class="contact-detail" style="padding-bottom: 10px">
              <label for="email" class="form-label">Contact Detail</label>
              <input
                style="width: 100%"
                type="text"
                class="form-control"
                formControlName="email"
                placeholder="Email"
              />
              <div
                *ngIf="
                  passengerForm.get('email')?.invalid &&
                  passengerForm.get('email')?.touched
                "
                class="text-danger"
              >
                Invalid email format
              </div>
            </div>
            <div style="display: flex; justify-content: space-between">
              <label for="">Primary Passenger</label>
            </div>
            <div
              class="input-group mb-3"
              style="padding-bottom: 10px; display: flex; gap: 10px"
            >
              <input
                style="width: 40%"
                type="text"
                class="form-control"
                formControlName="firstName"
                placeholder="First Name"
              />
              <input
                style="width: 40%"
                type="text"
                class="form-control"
                formControlName="lastName"
                placeholder="Last Name"
              />
            </div>
            <div class="form-check-inline mb-3">
              <input
                class="form-check-input"
                type="radio"
                formControlName="gender"
                value="Male"
                id="flexRadioDefault1"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault1"
                >Male</label
              >
            </div>
            <div class="form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                formControlName="gender"
                value="Female"
                id="flexRadioDefault2"
              />
              <label class="form-check-label" for="flexRadioDefault2"
                >Female</label
              >
            </div>
            <div
              class="input-group"
              style="padding-bottom: 10px; display: flex; gap: 10px"
            >
              <select class="form-select" aria-label="Default select example">
                <option selected>Code</option>
                <option value="+254">+254</option>
              </select>
              <input
                style="width: 33%"
                type="text"
                class="form-control"
                formControlName="mobile"
                placeholder="Mobile Number"
              />
            </div>
            <div
              class="input-group"
              style="padding-bottom: 10px; display: flex; gap: 10px"
            >
              <select class="form-select" aria-label="Default select example">
                <option selected>Nationality</option>
                <option value="Kenyan">Kenyan</option>
                <option value="Tanzania">Tanzania</option>
              </select>
              <input
                style="width: 33%"
                type="text"
                class="form-control"
                formControlName="idNo"
                placeholder="Identity Number"
              />
            </div>
          </div>
        </div>
      </form>
      <div
        class="modal-footer"
        style="
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <p>KES 1,700</p>
        <button type="button" class="payBtn" (click)="tripReview()">
          Proceed To Pay
        </button>
      </div>
    </div>
  </div>
</div>
