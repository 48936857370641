<div class="col-12" style="padding: 0">
  <div
    class="modal fade"
    id="payNowModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content custom-modal">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel5">
            Enter Bus registration
          </h5>
          <button
            (click)="closePayNowModal()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3 d-flex align-items-center">
            <input
              type="text"
              class="form-control me-2"
              id="bus_registration"
              placeholder="Bus registration"
            />
            <button
              type="button"
              class="bookBtn"
              (click)="registrationHandler()"
            >
              Enter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
