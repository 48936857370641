<div class="col-12 cta d-flex align-items-center" style="padding: 0">
  <!-- <img
      src="../../../assets/Lipanauli/Local bus commuting Nairobi.png"
      alt="Local bus commuting in Nairobi"
      style="width: 100%; height: auto"
    /> -->
  <div class="col-2 right" style="flex: 1">
       <img src="../../../assets/Lipanauli/Asset 84-8.png" alt="">
  </div>
  <div class="col-8 mid d-flex flex-wrap" style="flex: 2">
    <div class="col-lg-3 col-md-4 item text-center">
      <h5>Traveling during COVID-19</h5>
      <p>Find all current information about our network.</p>
      <a href="#">Click here > </a>
    </div>
    <div class="col-lg-3 col-md-4 item text-center">
      <h4>Comfort on board</h4>
      <p>
        Our buses are equipped with large and comfortable seats, Wi-Fi, and
        power outlets.
      </p>
      <a href="#">Our onboard service > </a>
    </div>
    <div class="col-lg-3 col-md-4 item text-center">
      <h5>Largest bus network in Kenya</h5>
      <p>
        Choose from over 2,500 travel destinations in 47 counties and discover
        Kenya with Lipanauli.
      </p>
      <a href="#">Our route network ></a>
    </div>
    <div class="col-lg-3 col-md-4 item text-center">
      <h5>Travel environmentally friendly</h5>
      <p>
        Our efficient coaches are proven to have an excellent carbon footprint
        per driven passenger-kilometer.
      </p>
      <a href="#">Bus travel and environment > </a>
    </div>
  </div>
  <div class="col-2 right" style="flex: 1">
    <img src="../../../assets/Lipanauli/Asset 93-8.png" alt="">
  </div>
</div>
