<div class="container-fluid hero-section" style="padding: 0">
  <div class="row hero">
    <div class="nav-section">
      <ul>
        <li>Home</li>
      </ul>
      <div class="login" (click)="loginUser()">
        <p>Sign In / Sign Out</p>
        <p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon icon-tabler icons-tabler-outline icon-tabler-user-circle"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
            <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
            <path
              d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"
            />
          </svg>
        </p>
      </div>
      <div class="social-icons" style="display: flex; flex-direction: column">
        <div class="img">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="icon icon-tabler icons-tabler-filled icon-tabler-brand-facebook"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M18 2a1 1 0 0 1 .993 .883l.007 .117v4a1 1 0 0 1 -.883 .993l-.117 .007h-3v1h3a1 1 0 0 1 .991 1.131l-.02 .112l-1 4a1 1 0 0 1 -.858 .75l-.113 .007h-2v6a1 1 0 0 1 -.883 .993l-.117 .007h-4a1 1 0 0 1 -.993 -.883l-.007 -.117v-6h-2a1 1 0 0 1 -.993 -.883l-.007 -.117v-4a1 1 0 0 1 .883 -.993l.117 -.007h2v-1a6 6 0 0 1 5.775 -5.996l.225 -.004h3z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="icon icon-tabler icons-tabler-filled icon-tabler-brand-twitter"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M14.058 3.41c-1.807 .767 -2.995 2.453 -3.056 4.38l-.002 .182l-.243 -.023c-2.392 -.269 -4.498 -1.512 -5.944 -3.531a1 1 0 0 0 -1.685 .092l-.097 .186l-.049 .099c-.719 1.485 -1.19 3.29 -1.017 5.203l.03 .273c.283 2.263 1.5 4.215 3.779 5.679l.173 .107l-.081 .043c-1.315 .663 -2.518 .952 -3.827 .9c-1.056 -.04 -1.446 1.372 -.518 1.878c3.598 1.961 7.461 2.566 10.792 1.6c4.06 -1.18 7.152 -4.223 8.335 -8.433l.127 -.495c.238 -.993 .372 -2.006 .401 -3.024l.003 -.332l.393 -.779l.44 -.862l.214 -.434l.118 -.247c.265 -.565 .456 -1.033 .574 -1.43l.014 -.056l.008 -.018c.22 -.593 -.166 -1.358 -.941 -1.358l-.122 .007a.997 .997 0 0 0 -.231 .057l-.086 .038a7.46 7.46 0 0 1 -.88 .36l-.356 .115l-.271 .08l-.772 .214c-1.336 -1.118 -3.144 -1.254 -5.012 -.554l-.211 .084z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"
            />
            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
            <path d="M16.5 7.5l0 .01" />
          </svg>
        </div>
        <p>@Lipanauli</p>
      </div>
    </div>
    <div class="col-6 form-card">
      <img
        style="height: 50px; width: 150px; margin-top: 80px"
        src="../../../assets/Lipanauli/Lipanauli fare prices.png"
        alt=""
      />
      <p class="phone-cta" style="height: 40px; width: 250px; margin-top: 40px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="icon icon-tabler icons-tabler-filled icon-tabler-phone"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M9 3a1 1 0 0 1 .877 .519l.051 .11l2 5a1 1 0 0 1 -.313 1.16l-.1 .068l-1.674 1.004l.063 .103a10 10 0 0 0 3.132 3.132l.102 .062l1.005 -1.672a1 1 0 0 1 1.113 -.453l.115 .039l5 2a1 1 0 0 1 .622 .807l.007 .121v4c0 1.657 -1.343 3 -3.06 2.998c-8.579 -.521 -15.418 -7.36 -15.94 -15.998a3 3 0 0 1 2.824 -2.995l.176 -.005h4z"
          />
        </svg>
        0790 215 215 | 0737 740 740
      </p>

      <h1 style="letter-spacing: 1px" class="header">
        <span>Fare</span> ya kisure
      </h1>
      <div class="card">
        <div class="card-body">
          <form [formGroup]="tripForm">
            <div class="input-item">
              <div class="me-2">
                <label for="from" class="form-label">From</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="From"
                  formControlName="from"
                  list="datalistOptions"
                  (change)="selectedCity($any($event.target).value)"
                />
                <datalist id="datalistOptions">
                  <option
                    *ngFor="let suggestion of sourceCities"
                    [value]="suggestion.city_name"
                  ></option>
                </datalist>
              </div>
              <div>
                <label for="to" class="form-label">To</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="To"
                  formControlName="to"
                  list="destinationCities"
                />
                <datalist id="destinationCities">
                  <option
                    *ngFor="let suggestion of destinationCities"
                    [value]="suggestion.city_name"
                  ></option>
                </datalist>
              </div>
            </div>
            <div class="input-item">
              <div class="me-2">
                <label for="departure" class="form-label">Departure</label>
                <input
                  type="date"
                  class="form-control"
                  formControlName="departure"
                />
              </div>
              <div>
                <label for="passengers" class="form-label"
                  >Passengers/ luggage</label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="passengers"
                  placeholder="1 Adult, 0 Baggage"
                />
              </div>
            </div>
            <div class="bookBtns">
              <button (click)="bookTrip()" type="button" class="search">
                Search
              </button>

              <button (click)="payNowTrip()" type="button" class="paynow">
                Pay Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-6 right" style="padding: 0">
      <img
        class="right-img"
        src="../../../assets/Lipanauli/Nairobi transport solutions.png"
        alt=""
      />
    </div>
  </div>
  <div class="row">
    <app-lipanaulislider></app-lipanaulislider>
  </div>
  <div class="row">
    <app-lipanauliapp></app-lipanauliapp>
  </div>
  <div class="row">
    <app-cta></app-cta>
  </div>
  <div class="row">
    <app-footer></app-footer>
  </div>
</div>
<app-loginmodal></app-loginmodal>
<app-paynowmodal></app-paynowmodal>
<app-buslistmodal></app-buslistmodal>
<app-customerdetailsmodal></app-customerdetailsmodal>
<app-pay-for-ticketmodal></app-pay-for-ticketmodal>
