 <div class="col-12" style="padding: 0">
      <carousel style="width: 100%" [showIndicators]="false">
        <slide>
          <img
            src="../../../assets/Lipanauli/Nairobi Bus Booking.png"
            alt="first slide"
            style="display: block; width: 100%; height: auto"
          />
        </slide>
        <slide>
          <img
            src="../../../assets/Lipanauli/Book bus seat Nairobi.png"
            alt="second slide"
            style="display: block; width: 100%; height: auto"
          />
        </slide>
        <slide>
          <img
            src="../../../assets/Lipanauli/Nairobi bus schedules.png"
            alt="third slide"
            style="display: block; width: 100%; height: auto"
          />
        </slide>
      </carousel>
    </div>