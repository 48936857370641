<div
  class="modal fade"
  id="payForTicketModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel4"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable modal-xl d-flex align-items-center justify-content-center">
    <div class="modal-content slide-in-modal">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel4">Pay For Trip</h5>
        <button
        (click)="closePayNowModal()"
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>

      <div class="row mb-3">
        <div class="col-7">
          <div class="card modalCard" style="padding-top: 20px">
            <div
              class="card-body"
              style="display: flex; flex-direction: column"
            >
              <div class="d-flex align-items-center" style="padding: 40px 0px">
                <select
                  style="width: 90px"
                  class="form-select me-2"
                  aria-label="Default select example"
                >
                  <option selected>Code</option>
                  <option value="254">254</option>
                </select>

                <input
                  style="width: 200px"
                  type="tel"
                  class="form-control me-2"
                  id="exampleFormControlInput1"
                  placeholder="Phone"
                />

                <button type="button" class="paymentBtn" (click)="stkBtn()">
                  Make Payment
                </button>
              </div>

              <div class="tripDetails">
                <div class="column">
                  <p>
                    Route: <span *ngIf="bookingInfo">{{ bookingInfo.route_name }}</span>
                  </p>
                  <p>
                    Booking Date: <span *ngIf="bookingInfo">{{ bookingInfo.start_date }}</span>
                  </p>
                </div>
                <div class="column">
                  <p>
                    Departure Time:
                    <span *ngIf="bookingInfo">{{ bookingInfo.departure_time }}</span>
                  </p>
                  <p>
                    Boarding Point:
                    <span *ngIf="bookingInfo">{{ bookingInfo.route_cities[0].city_name }}</span>
                  </p>
                </div>
                <div class="column">
                  <p>
                    Dropping Point:
                    <span *ngIf="bookingInfo">{{ bookingInfo.route_cities[1].city_name }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="card modalCard">
            <div class="card-body">
              <div class="fare">
                <p style="font-weight: 600">Fare Details</p>
                <p style="font-weight: 600">Total</p>
              </div>
              <div class="fare">
                <p>Onward Fare</p>
                <p>KES {{ bookingData.total }}</p>
              </div>
              <div class="fare">
                <p>Wallet</p>
                <p>KES 0</p>
              </div>
            </div>
            <div class="card-footer">
              <P>Total Payable</P>
              <p>KES 2,200</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>