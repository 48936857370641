<div class="col-12 footer">
  <div class="left">
    <img src="../../../assets/Lipanauli/Convenient bus booking Nairobi.png" alt="">
  </div>
  <div class="right">
    <p>Privacy policy</p>
    <p>Passenger rights</p>
    <p>Legal notice</p>
    <p>change cookie settings</p>
    <p>&copy; 2024. Lipanauli</p>
  </div>
</div>
